import { isNonEmptyString } from '@wise/utils'
import { uniq } from 'lodash/fp'

import { dedupeLocations } from '~shared/utils/depots'

import type { OnboardingApplication } from '@/applications/graphql/types'
import type { UTRReport } from '@/reports/graphql/types'
import type { Driver } from '@/subcontractors/graphql/types'

export const getDriverName = (driver: {
  firstName?: string | undefined | null
  lastName?: string | undefined | null
}): string => [driver.firstName, driver.lastName].filter(Boolean).join(' ')

const isDefined = <T>(item: T): item is Mandatory<T> => Boolean(item)

export const getDriverCustomers = (driver: Driver): string | undefined => {
  const customerNames = (driver.engagements ?? [])
    .map((eng) => eng.app?.customer.name)
    .filter(isDefined)
    .sort()
    .join(', ')

  return customerNames || undefined
}

export const getDriverDepot = (driver: {
  engagements?: Pick<
    Mandatory<Pick<Driver, 'engagements'>['engagements']>[number],
    'locations'
  >[]
}): string => {
  const locations = dedupeLocations(
    driver.engagements?.flatMap((gig) => gig.locations) ?? [],
  )
    .map((depot) => depot.name)
    .filter(isNonEmptyString)
    .join(', ')

  return locations ?? '-'
}

export const getDriverRNumbers = (
  driver: Pick<Driver | UTRReport, 'rNumbers'>,
): string => {
  const unique = uniq(driver.rNumbers?.filter(isNonEmptyString) ?? [])
  return unique.length === 0 ? '-' : unique.join('\n')
}

export const doesDriverHaveAppInstalled = (
  verificationPassCode: OnboardingApplication['subcontractor']['verificationPassCode'],
): boolean => verificationPassCode === null
