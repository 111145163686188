import { get } from 'lodash'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import * as React from 'react'
import { Toaster } from 'react-hot-toast'

import AuthOverlay from '~shared/components/AuthOverlay/AuthOverlay'
import ErrorOverlay from '~shared/components/ErrorOverlay/ErrorOverlay'
import LoadingOverlay from '~shared/components/LoadingOverlay/LoadingOverlay'
import MaintenanceMode from '~shared/components/MaintenanceMode/MaintenanceMode'
import ModalWrapper from '~shared/components/Modal/ModalWrapper'
import NetworkProvider from '~shared/components/NetworkProvider/NetworkProvider'
import NotificationsProvider from '~shared/components/NotificationsProvider/NotificationsProvider'
import ThemeProvider from '~shared/components/ThemeProvider/ThemeProvider'
import UpgradeChecker from '~shared/components/UpgradeChecker/UpgradeChecker'
import {
  APP_BUILD_SHA,
  APP_BUILD_TAG,
  APP_VERSION,
} from '~shared/config/version'
import { nextRoot } from '~shared/consts/next'
import { FeatureFlagProvider } from '~shared/featureFlags/context'
import useMaintenanceMode, {
  MaintenanceModeContext,
} from '~shared/hooks/useMaintenanceMode'
import { QueryParamsProvider } from '~shared/hooks/useParams/context'
import { MiddlewareProvider } from '~shared/middleware/MiddlewareProvider'
import { setAnalyticsScreen } from '~shared/services/analytics/analytics'
import { getErrorBoundary } from '~shared/services/bugsnag/client'
import { isServerSide } from '~shared/services/context'
import { AuthProvider } from '~shared/services/firebase/auth/context'
import { useGoogleTagManager } from '~shared/services/gtm/useGoogleTagManager'
import { trpc } from '~shared/services/trpc'
import { SocketProvider } from '~shared/services/ws'

import { initialiseI18n } from '../shared/i18n/i18n'
import { Layout, hasLayout } from '../types'

import '~shared/styles/global.scss'
import 'react-day-picker/dist/style.css'

const DefaultLayout: Layout = ({ children }) => <>{children}</>

const ErrorBoundary = getErrorBoundary()

initialiseI18n()

const App = ({ Component, pageProps }: AppProps): JSX.Element => {
  useGoogleTagManager()
  const router = useRouter()

  React.useEffect(() => {
    // eslint-disable-next-line no-console
    console.table({
      version: APP_VERSION,
      build: APP_BUILD_TAG,
      sha: APP_BUILD_SHA,
    })
  }, [])

  React.useEffect(() => {
    if (!router.isReady) return
    setAnalyticsScreen(router.pathname)
  }, [router.isReady, router.pathname])

  React.useEffect(() => {
    const handleRouteChanged = (_: unknown, options: unknown) => {
      const isShallow = get(options, 'shallow', false)
      if (isServerSide() || isShallow) return
      nextRoot?.scrollTo({ top: 0 })
    }

    router.events.on('routeChangeComplete', handleRouteChanged)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChanged)
    }
  }, [router.events])

  const [Layout, layoutProps] = React.useMemo(() => {
    return hasLayout(Component)
      ? [Component.layout, Component.layoutProps]
      : [DefaultLayout, {}]
  }, [Component])

  const maintenance = useMaintenanceMode()
  const { status: maintenanceMode, config: maintenanceConfig } = maintenance

  return (
    <ErrorBoundary FallbackComponent={ErrorOverlay}>
      <QueryParamsProvider>
        <MaintenanceModeContext.Provider value={maintenance}>
          <LoadingOverlay enabled={maintenanceMode === 'loading'} />
          <NetworkProvider>
            <AuthProvider>
              <FeatureFlagProvider>
                <SocketProvider>
                  <NotificationsProvider>
                    <Toaster
                      toastOptions={{
                        position: 'bottom-right',
                      }}
                    />
                    <UpgradeChecker />
                    <div id='app-root' className='h-full w-full bg-grey'>
                      <AuthOverlay>
                        <ThemeProvider>
                          <ModalWrapper>
                            {maintenanceMode === 'in-maintenance' ? (
                              <MaintenanceMode config={maintenanceConfig} />
                            ) : (
                              <MiddlewareProvider>
                                <Layout {...layoutProps}>
                                  <Component {...pageProps} />
                                </Layout>
                              </MiddlewareProvider>
                            )}
                          </ModalWrapper>
                        </ThemeProvider>
                      </AuthOverlay>
                    </div>
                  </NotificationsProvider>
                </SocketProvider>
              </FeatureFlagProvider>
            </AuthProvider>
          </NetworkProvider>
        </MaintenanceModeContext.Provider>
      </QueryParamsProvider>
    </ErrorBoundary>
  )
}

const TrpcApp = trpc.withTRPC(App)

/**
 * This may look like it does nothing, but it does!
 *
 * We are opting-out of Automatic Static Optimization here (See https://nextjs.org/docs/advanced-features/automatic-static-optimization)
 * This means we will never pre-render/server-side-render any pages.
 * This ultimately doesn't really cause any issues, as we have to wait for
 * Firebase in order to render page components anyway, and don't fully
 * pre-render any pages anyway.
 */
TrpcApp.getInitialProps = () => ({})

export default TrpcApp
