import dayjs, { ConfigType, Dayjs } from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isBetweenPlugin from 'dayjs/plugin/isBetween'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezonePlugin from 'dayjs/plugin/timezone'
import utcPlugin from 'dayjs/plugin/utc'

import { isDefined } from './array'
import { isNonEmptyString } from './string'

// Load locales
import 'dayjs/locale/en'
import 'dayjs/locale/fr'

dayjs.extend(utcPlugin)
dayjs.extend(relativeTime)
dayjs.extend(isBetweenPlugin)
dayjs.extend(advancedFormat)
dayjs.extend(timezonePlugin)

type Optional<T> = T | null | undefined

export const parseDate = (date?: Optional<ConfigType>): Optional<Dayjs> => {
  if (!date) return null
  const value = dayjs(date)
  return value.isValid() ? value : null
}

export const now = (): Dayjs => dayjs()

export const djs = dayjs

export const hoursSinceDate = (date: ConfigType): number =>
  dayjs(date).diff(new Date(), 'hours')

export const dateToDDMMYY = (date: ConfigType): string =>
  dayjs(date).format('DD/MM/YY')

export const dateToInputFormat = (date: ConfigType): string =>
  dayjs(date).format('YYYY-MM-DD')

export const dateToDayFormat = (date: ConfigType): string =>
  dayjs(date).format('MMM D, YYYY')

export const dateToMonthYearFormat = (date: ConfigType): string =>
  dayjs(date).format('MMM, YYYY')

export const formatDateForApi = (date: Optional<ConfigType>): string | null => {
  if (!date) return null
  return dayjs(date).format('YYYY-MM-DD')
}

export const getFormattedDate = (
  from: Optional<ConfigType>,
  to: Optional<ConfigType>,
): string => {
  if (isDefined(from) && isDefined(to)) {
    return dateToDDMMYY(from) + ' to ' + dateToDDMMYY(to)
  }
  if (isDefined(from)) {
    return dateToDDMMYY(from) + ' to present day'
  }
  if (isDefined(to)) {
    return `Until ${dateToDDMMYY(to)}`
  }
  return 'Current'
}

export const prettyDate = (
  datestring: Optional<ConfigType>,
): string | undefined => {
  if (isNonEmptyString(datestring))
    return dayjs(datestring).format('DD/MM/YYYY')
  return undefined
}

export const formatDateAndTime = (
  date: ConfigType,
  time: string,
): string | undefined => {
  const res = /(\d{1,2}):(\d{1,2})/.exec(time)
  if (!res) return undefined
  const [, hrs, mins] = res
  const result = dayjs(date)
    .set('hours', Number(hrs))
    .set('minutes', Number(mins))
    .set('seconds', 0)
    .set('milliseconds', 0)
  if (result.isValid()) return result.toDate().toISOString()
  return undefined
}

export const formatShortDate = (date: ConfigType): string => {
  return dayjs(date).format('Do MMM YYYY')
}

export const getExpiryStatus = (
  date: Dayjs,
): 'Ok' | 'Expiring soon' | 'Expired' => {
  const expiryDay = date.startOf('day')
  const today = now().startOf('day')

  const dayDiff = expiryDay.diff(today, 'days')

  if (dayDiff < 0) return 'Expired'
  if (dayDiff <= 28) return 'Expiring soon'
  return 'Ok'
}

export const getBritishDate = (day: Date): Dayjs => {
  try {
    const d = djs(day)
    const [year, month, date] = [
      d.get('year'),
      d.get('month') + 1,
      d.get('date'),
    ]
    return djs.tz(`${year}-${month}-${date}`, 'Europe/London')
  } catch (error) {
    return djs('invalid')
  }
}

export const getYearsRemaining = (date: Dayjs): string => {
  const yearsRemaining: number = date.diff(now(), 'years')
  if (yearsRemaining < 1) {
    return 'Less than a year'
  } else if (yearsRemaining === 1) {
    return `Over a year`
  } else {
    return `Over ${yearsRemaining} years`
  }
}

export const DaysOfTheWeek = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
}

export const getDayName = (day: keyof typeof DaysOfTheWeek, locale: string) => {
  const date = djs().locale(locale).set('day', DaysOfTheWeek[day])
  return date.format('dddd')
}

export const dateToRFC3339Nano = (date: ConfigType): string =>
  dayjs(date).utc().format('YYYY-MM-DDTHH:mm:ss.SSS000000[Z]')
