import { NextPage } from 'next'
import * as React from 'react'
import { Control } from 'react-hook-form'

// eslint-disable-next-line @typescript-eslint/ban-types
export type EmptyProps = {}
// eslint-disable-next-line @typescript-eslint/ban-types
export type EmptyObject = {}

export type Layout<T = EmptyProps> = React.FC<T>

export type ExtractProps<TComponentOrTProps> =
  TComponentOrTProps extends React.ComponentType<infer TProps>
    ? TProps
    : TComponentOrTProps

type InferredComponent<ComponentType> =
  ComponentType extends React.ComponentType<infer Props>
    ? React.ComponentType<Props>
    : React.ComponentType<EmptyProps>

// Has types
type HasLayout<LayoutComponent = React.FC> = {
  layout: LayoutComponent
  layoutProps?: ExtractProps<LayoutComponent>
}

// Base page types
type BaseAppPage<Props extends EmptyProps = EmptyProps> = NextPage<Props>

type BaseAppPageWithMiddleware<Props extends EmptyProps = EmptyProps> =
  BaseAppPage<Props>

type BaseAppPageWithLayout<
  LayoutType extends React.ComponentType<EmptyProps> = React.FC,
  Props extends EmptyProps = EmptyProps,
> = BaseAppPage<Props> & HasLayout<LayoutType>

// App page type unions
export type AppPage<Props extends EmptyProps = EmptyProps> =
  | BaseAppPage<Props>
  | BaseAppPageWithMiddleware<Props>

export type AppPageWithLayout<
  LayoutType = React.FC,
  Props extends EmptyProps = EmptyProps,
> = BaseAppPageWithLayout<InferredComponent<LayoutType>, Props>

export const hasLayout = <LayoutType = React.FC>(
  item: NextPage | AppPage | AppPageWithLayout,
): item is BaseAppPageWithLayout<InferredComponent<LayoutType>> => {
  return (item as HasLayout).layout !== undefined
}

export type ValueOption<Value = string, Label = string | React.ReactChild> = {
  value: Value
  label: Label
}

export type Option<T = EmptyObject> = {
  value: string | number
  label: string | React.ReactChild
} & T

export const isOption = <T = Option>(item?: unknown): item is T => {
  return (
    Boolean(item) &&
    typeof item === 'object' &&
    (item as Option).value !== undefined &&
    (item as Option).label !== undefined
  )
}

export type FileObject = {
  name: string
  size: number
  data: string
  type: string
}

export type ControlledProps<T = unknown> = {
  name: string
  defaultValue?: T
  control: Control
}

export type NullFields<T> = { [P in keyof T]: T[P] | null }
