import {
  McpNotificationFragment,
  useGetAllNotificationsQuery,
} from '@wise/graphql'
import { isNonEmptyString, showToastNotification } from '@wise/utils'
import * as React from 'react'

import { notify } from '~shared/services/bugsnag/client'
import {
  SocketStatus,
  useSocket,
  useSocketEvent,
  useSocketStatus,
} from '~shared/services/ws'
import { SocketEvent } from '~shared/services/ws/events'

interface UseNotificationManagerOptions {
  mainContractorId: Optional<string>
  userId: string
}

export interface UseNotificationManager {
  enabled: boolean
  error?: Error
  loading: boolean
  notifications: McpNotificationFragment[]
  unread: number
  status: SocketStatus
  markAsRead: (id: string) => void
}

const useNotificationManager = ({
  mainContractorId,
  userId,
}: UseNotificationManagerOptions): UseNotificationManager => {
  const socket = useSocket()

  const socketError = React.useMemo<UseNotificationManager['error']>(() => {
    if (socket === null)
      return new Error('Notification socket is not connected')
  }, [socket])

  const status = useSocketStatus(socket)

  const { data, loading, error, refetch } = useGetAllNotificationsQuery(
    isNonEmptyString(mainContractorId)
      ? {
          variables: { userId, mainContractorId },
        }
      : { skip: true },
  )

  const [unreadCount, setUnreadCount] = React.useState(0)

  const markAsRead = React.useCallback<UseNotificationManager['markAsRead']>(
    async (id) => {
      const notification = data?.notifications.find((n) => n.id === id)
      if (!notification || !notification.read) return
      setUnreadCount((prev) => prev - 1)
    },
    [setUnreadCount, data?.notifications],
  )

  React.useEffect(() => {
    setUnreadCount(data?.notifications?.filter((n) => !n.read).length ?? 0)
  }, [data?.notifications])

  useSocketEvent(SocketEvent.NEW_NOTIFICATION, async () => {
    try {
      await refetch()
      showToastNotification({
        type: 'info',
        description: 'You have a new notification.',
      })
    } catch (error) {
      notify(error)
      showToastNotification({
        type: 'error',
        description: 'An issue occurred while fetching notifications.',
      })
    }
  })

  return {
    enabled: true,
    error: error || socketError,
    loading,
    notifications: data?.notifications.filter((n) => 'id' in n) || [],
    unread: unreadCount,
    status,
    markAsRead,
  }
}

export default useNotificationManager
