import { DocumentForm } from '@/documents/validation/document'

export const INITIAL_DOCUMENT_FORM: DocumentForm = {
  title: '',
  content: [{ type: 'p', children: [{ text: '' }] }],
  customers: [],
  type: null,
}

export const WISE_TERMS_AND_CONDITIONS_RELATIVE_LINK =
  '/documents/wise-modulr-terms-and-conditions-2022-04-20.pdf'
export const WISE_PRIVACY_POLICY_ABSOLUTE_LINK =
  'https://withwise.com/about-wise/privacy-policy/'
export const ADD_DRIVERS_CSV_MC_ONBOARDING_DASHBOARD =
  '/documents/driver-import-template.csv'
