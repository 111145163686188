import { OnboardingStageEnum } from '@/mc-onboarding/middleware/type'

import { auth } from './auth'
import { featureFlag } from './featureFlags'
import { gatekeeper } from './gatekeeper'
import { guest } from './guest'
import { hasPassedKyc } from './hasPassedKyc'
import { mcHasDeductionsEnabled } from './mcHasDeductionsEnabled'
import { mcOnboarding } from './mcOnboarding'
import { mcStatus } from './mcStatus'
import { mode } from './mode'
import { modulr } from './modulr'
import { permission } from './permission'

export const M = {
  auth,
  guest,
  modulr,
  mcOnboarding,
  permission,
  mcStatus,
  gatekeeper,
  featureFlag,

  WAP: mode({ mode: 'WAP' }),
  MCP: mode({ mode: 'MCP' }),
  NAP: mode({ mode: 'NAP' }),

  Guest: guest(),
  Authenticated: auth(),

  Permissions: {
    Wise: {
      Customer: {
        Get: permission({ permission: ['wise.customer.get'] }),
        List: permission({ permission: ['wise.customer.list'] }),
      },
      MainContractor: {
        Get: permission({ permission: ['wise.mainContractor.get'] }),
        List: permission({ permission: ['wise.mainContractor.list'] }),
      },
    },
    Own: {
      MainContractor: {
        Get: permission({ permission: ['own.mainContractor.get'] }),
      },
    },
    User: {
      List: permission({ permission: ['*.user.list'] }),
    },
  },

  MainContractor: {
    Active: mcStatus({ status: 'ACTIVE' }),
    Onboarding: mcStatus({ status: 'ONBOARDING' }),
  },

  Modulr: {
    Default: modulr(),
  },

  MCOnboarding: {
    HasPassedKYC: hasPassedKyc(),
    Stages: {
      CreateAccount: mcOnboarding({ stage: OnboardingStageEnum.CreateAccount }),
      SetupCompany: mcOnboarding({ stage: OnboardingStageEnum.SetupCompany }),
      Contracts: mcOnboarding({ stage: OnboardingStageEnum.Contracts }),
      ModulrHold: mcOnboarding({ stage: OnboardingStageEnum.ModulrHold }),
      ModulrSuccess: mcOnboarding({ stage: OnboardingStageEnum.ModulrSuccess }),
    },
  },

  FeatureFlag: {
    SchedulingEnabled: featureFlag({ featureFlag: 'schedulingEnabled' }),
    DeductionsEnabled: featureFlag({ featureFlag: 'deductionsEnabled' }),
    ExpiryHubEnabled: featureFlag({ featureFlag: 'expiryHubEnabled' }),
    IncidentsEnabled: featureFlag({ featureFlag: 'incidentsEnabled' }),
    SignedPoliciesReportEnabled: featureFlag({
      featureFlag: 'signedPoliciesReportEnabled',
    }),
    WorklogEnabled: featureFlag({ featureFlag: 'worklogEnabled' }),
  },

  MCHasDeductionsEnabled: {
    Default: mcHasDeductionsEnabled(),
    AllowedOnWap: mcHasDeductionsEnabled({ allowedOnWap: true }),
  },
}

export default M
