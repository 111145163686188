import produce from 'immer'
import React from 'react'
import create from 'zustand'

import { createComponent } from '~shared/utils/createComponent'

import LoadingModal from './preset/LoadingModal/LoadingModal'

export interface ModalProps {
  id: string
  canBeClosed?: boolean
  closeButtonClassName?: string
}

export interface Modal<T extends ModalProps = ModalProps> extends ModalProps {
  component: React.ComponentType<T> | React.ReactChild
}

interface ModalState {
  stack: Modal[]
}

type ModalStateMutations = {
  push: (modal: Modal) => void
  pop: () => void
  remove: (modalId: Modal['id'], ...modalIds: Modal['id'][]) => void
  setComponent: (modalId: Modal[`id`], component: Modal[`component`]) => void
  clear: () => void
}

const modalStore = create<ModalState & ModalStateMutations>((set) => {
  return {
    stack: [],
    clear: () => set({ stack: [] }),
    pop: () =>
      set((state) => produce(state, (draft) => void draft.stack.pop())),
    push: (modal) =>
      set((state) =>
        produce(state, (draft) => {
          if (draft.stack.some((m) => m.id === modal.id)) return
          void draft.stack.push(modal)
        }),
      ),
    remove: (...ids) => {
      set((state) =>
        produce(state, (draft) => {
          draft.stack = draft.stack.filter((m) => !ids.includes(m.id))
        }),
      )
    },
    setComponent: (id, component) =>
      set((state) =>
        produce(state, (draft) => {
          const modal = draft.stack.find((m) => (m.id = id))
          if (modal) modal.component = component
        }),
      ),
  }
})

export const useModalState = modalStore

export const pushModal = useModalState.getState().push
export const popModal = useModalState.getState().pop
export const removeModal = useModalState.getState().remove
export const clearModals = useModalState.getState().clear
export const setComponent = useModalState.getState().setComponent

export const pushLoadingModal = (id: string, content?: React.ReactChild) =>
  pushModal({
    id,
    component: createComponent(LoadingModal, { children: content }),
    canBeClosed: false,
  })
